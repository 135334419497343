/**
 * Envía datos a la URL especificada y maneja la respuesta.
 *
 * @param {object} data - Los datos que se enviarán.
 * @param {function} setSendtoExcel - Una función para establecer el estado de envío de datos a Excel.
 * @param {function} setModal - Una función para establecer el estado del modal.
 * @param {function} setResponse - Una función para establecer los datos de respuesta.
 */
const send_data = (data, setSendtoExcel, setModal, setResponse) => {
  
    setSendtoExcel(true);
    const url = 'https://script.google.com/macros/s/AKfycbwoB4NhuXeaMCLzMA7_I2enjEdmByS03IOXmlHYbQnZsJ_JBigqen9uvU1EGUbDYwM1fg/exec';
    const temp = data.Campaña;
    data.Campaña = data.Pagaduria;
    data.Pagaduria = temp;
  
    fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        body: new URLSearchParams(data),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })
    .then(() => console.log('Solicitud enviada'))
    .then(data => {
        setSendtoExcel(false);
        setModal(true);
        // setResponse(data['row']);
    })
    .catch(error => {
        console.error('Error:', error);
    });
}

export { send_data };
