import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import "./Header.css";

/**
 * Renderiza el componente Navbar.
 *
 * @param {function} setAcceptedLogin - Una función para establecer el estado de inicio de sesión aceptado.
 * @return {JSX.Element} El componente Navbar renderizado.
 */

const NavbarComponent = ({ setAceptedLogin }) => {
  /**
   * Maneja el proceso de inicio de sesión eliminando la cookie 'loggedIn'
   * y estableciendo el estado 'acceptedLogin' en false.
   *
   * @return {void} Esta función no devuelve ningún valor.
   */
  const handleLogin = () => {
    Cookies.remove("loggedIn");
    setAceptedLogin(false);
  };

  return (
    <div className="header" variant="dark" expand="lg">
      <div className="header-container">
        <div className="header-brand">DiBanka</div>
        <div className="container-user">
          <FontAwesomeIcon icon={faUser} color="white" className="profile-img"/>
          <p className="profile-name">{Cookies.get("userName")}</p>
        </div>
        <div className="salir">
          <NavLink to="/">
            <button id="salirbtn" onClick={handleLogin}>
              <FontAwesomeIcon icon={faSignOutAlt} />
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export { NavbarComponent };
